import React from 'react';
import { Link } from 'gatsby';

const Footer = () => {
    return (
        <footer className="main-color">
            <div className="md:flex justify-between  px-4 py-2 text-sm md:px-8">
                <div className="w-full md:w-1/2 lg:w-1/3 text-white p-2">
                    <h3 className="text-2xl text-center mb-2">Контакты</h3>
                    <p className="mb-2">
                        Адрес: 628383, Ханты-Мансийский Автономный округ - Югра
                        АО, Пыть-Ях г, 6 Пионерный мкр, дом № 30
                    </p>
                    <p className="mb-2">
                        Контактный телефон: : +7 3463 461 444
                    </p>
                    <p className="mb-2">Номер для SMS: +7 9322575263</p>
                    E-mail: proff461444@mail.ru
                </div>
                <div className="w-full md:w-1/2 lg:w-1/3 text-white p-2">
                    <h3 className="text-2xl text-center mb-2">
                        Официальный сайт
                    </h3>
                    <p className="mb-2 text-center">
                        ЧАСТНОЕ ОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ДОПОЛНИТЕЛЬНОГО
                        ПРОФЕССИОНАЛЬНОГО ОБРАЗОВАНИЯ ПРОФФ
                    </p>
                    <p className="mb-2 text-center">
                        При использовании материалов c сайта - ссылка на данный
                        сайт обязательна.
                    </p>
                    <p className="my-2 text-center font-bold">
                        © 2018 г. Все права защищены.
                    </p>
                </div>
                <div className="w-full md:w-1/2 lg:w-1/3 text-white p-2">
                    <div className="text-center mb-6 ">
                        <Link
                            to="/PersonalData"
                            className="text-lg text-center text-yellow-500 hover:text-yellow-300 "
                        >
                            Согласие на обработку ПДн
                        </Link>
                    </div>
                    <p className="mb-6 text-lg text-center">
                        Настоящий ресурс может содержать материалы{' '}
                        <span className="p-1 border rounded">12+</span>
                    </p>
                    <a href="http://moodle.proffadmin.beget.tech">
                        <p className=" text-blue-200 text-lg text-center hover:text-gray-500">
                            Система онлайн-тестирования
                        </p>
                    </a>
                    <a
                        id="specialButton"
                        href="/#"
                        className="bvi-open bg-gray-400 text-center uppercase z-50 text-white rounded p-2 tracking-wider fixed right-10 bottom-10 "
                    >
                        <img
                            src="https://lidrekon.ru/images/special.png"
                            alt="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ"
                            title="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ"
                        />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default React.memo(Footer);
