import { Link } from "gatsby";
import React, { useState } from "react";
import mLogo from "../images/mlogo.svg";

const Header = () => {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <header className="main-color">
      <div className="flex items-center flex-col lg:flex-row justify-between max-w-screen-xl py-2 px-4 ">
        <div className="w-full lg:w-fit flex grow justify-between">
          <Link to="/" className="mr-4">
            <img src={mLogo} alt="Logo" className="h-12 lg:h-20" />
          </Link>

          <button
            className="px-3 py-2 h-8 my-auto text-white border : ;
          border-white rounded lg:hidden"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg
              className="w-3 h-3 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } block lg:flex lg:items-center w-full lg:w-auto`}
        >
          {[
            {
              route: `/Map`,
              title: `Карта сайта`,
              dropdown: [],
            },
            {
              route: `/about`,
              title: `Сведения об образовательной организации`,
              dropdown: [
                "Основные сведения",
                "Структура и органы управления образовательной организации",
                "Документы",
                "Образование",
                "Платные образовательные услуги",
              ],
            },
            {
              route: `/Science`,
              title: `Научно-методическое сопровождение инновационной деятельности`,
              dropdown: [],
            },
            {
              route: `/Feedback`,
              title: `Отзывы и благодарственные письма`,
              dropdown: [],
            },
            {
              route: `/Advantages`,
              title: `Наши преимущества`,
              dropdown: [],
            },
            {
              route: `/contact`,
              title: `Контакты`,
              dropdown: [],
            },
          ].map((link) => (
            <Link
              className="block mt-4 text-white no-underline max-w-xs 
              lg:text-center lg:inline-block lg:mt-0 lg:ml-6"
              key={link.title}
              to={link.route}
              onClick={() => toggleExpansion(!isExpanded)}
            >
              {link.title}
              {link.dropdown.length > 0 && (
                <span className="ml-2 text-xs">&#9660;</span>
              )}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
};

export default Header;
